$c-primary: #ce1643;
$c-secandary: #ce1643;
$dots: 'background/vodafone-dots.png';
$progress-bar-overided: white;
$loading-bg-overrided: #ce1643;
$progress-bar-incomplete-overided: #a2050b;
$base-font-family: 'Montserrat', Arial, Helvetica, sans-serif;
$font-family-heading: 'Montserrat', Arial, Helvetica, sans-serif;

      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-SemiBold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-SemiBoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.checkbox {
  display: none;
}

.option_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  height: 2.8125rem; //45
  margin-bottom: 0.6875rem; //11
  cursor: pointer;
  &.checked {
    background: #f4f4f4;
    border-radius: 10px;
  }

  .check_mark_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 3.3125rem; //53
    height: 2.8125rem; //45

    img {
      width: 1.375rem; //22
      height: auto;
    }
  }

  .option_title {
    flex: 1;
  }
}
